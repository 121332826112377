import {Helmet} from 'react-helmet';
import {useState} from 'react';
import FullFilter from '../components/modules/fullFilter/fullFilter';
import useFilter from '../hooks/useFilter';
import useLocalStorage from '../hooks/useLocalStorage';
import * as styles from './playground.module.css';
import type {Filter, FilterDimensionsData} from '../interfaces/filters';

const mockupFilterData: FilterDimensionsData = {
    languages: {
        name: 'Language',
        type: 'checkbox',
        values: [],
    },
    year: {
        name: 'Year',
        type: 'checkbox',
        values: [{name: '2021', value: 2021}, {name: '2022', value: 2022}],
    },
    themeAttributes: {
        name: 'Theme',
        type: 'checkbox',
        values: [
            {name: 'City', value: 123},
            {name: 'Friends', value: 321},
            {name: 'Ninjago', value: 987},
        ],
    },
    versionAttributes: {
        name: 'Version',
        type: 'checkbox',
        values: [],
    },
    typeAttributes: {
        name: 'Type',
        type: 'checkbox',
        values: [],
    },
    uploadPolicyAttributes: {
        name: 'Upload policy',
        type: 'checkbox',
        values: [],
    },
};

const PlaygroundFilters = () => {
    const [localStorageFilters, setLocalStorareFilters] = useLocalStorage<Filter | null>('filter');
    const [searchQuery, setSearchQuery] = useState('');
    const {
        filters,
        toggleFilter,
        setFilter,
        setAllFilters,
    } = useFilter(localStorageFilters || undefined);

    const handleApplyFilters = (): void => {
        setLocalStorareFilters(filters);
    };

    return (
        <main className={styles.root}>
            <Helmet>
                <title>LEGO IX</title>
            </Helmet>
            <div className={styles.wrapper}>
                <FullFilter
                    data={mockupFilterData}
                    filters={filters}
                    filterMethods={{
                        toggleFilter,
                        setAllFilters,
                        applyFilters: handleApplyFilters,
                        setFilter,
                    }}
                    search={{
                        query: searchQuery,
                        applyQuery: setSearchQuery,
                    }}
                    render={() => (
                        <>
                            Table filtered with these: {JSON.stringify(filters)}
                        </>
                    )}
                />
            </div>
        </main>
    );
};

export default PlaygroundFilters;
